import Vue from 'vue'
import { ActionTree, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { fill, merge } from 'lodash'

interface BonusFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;
  filering: boolean;

  object: any;
  report_data: any[];
  merchants: any[];
  cities: any[];
  brands: any[];
  checkedRows: any[];
  merchant_categories: any[];
  form: any;
  filter: any;
  filter_ranges: any;
  blocked: boolean;
}

const $state: BonusFormState = {
  loading: false,
  loaded: false,
  filering: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  report_data: [],
  merchants: [],
  checkedRows: [],
  cities: [],
  brands: [],
  merchant_categories: [],
  form: {},
  filter: {
    city_id: null,
    brands: [],
    cities: [],
    merchant_categories: [],
    merchant_category_id: null,
    registration_type: 'all',
    outlets_count: [0, 0],
    receipts_count: [0, 0],
    receipts_sum: [0, 0],
    feedbacks_count: [0, 0],
    marks_count: [0, 0],
    common_mark: [0, 5],
    disсouted_bonuses: [0, 0],
    accural_bonuses: [0, 0],
    balance: [0, 0],
  },
  filter_ranges: null,
  blocked: false,
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  type: 'percent',
  start_date: null,
  end_date: null,
  merchants: [],
  value_feedback: null,
  value_mark: null,
  add_new_merchants: false,
  common: false,
  min_sum: null,
  programm_name: null,
  category_id: null,
}

const AVAILABLE_FIELDS = [
  'type', 'start_date', 'end_date', 'merchants', 'value_feedback', 'value_mark',
  'common', 'min_sum', 'programm_name', 'category_id', 'add_new_merchants',
]

export const $actions: ActionTree<BonusFormState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      if (id) {
        const vm = (this as any)._vm

        vm.$http
          .get(`/admins/rest/v1/bonus/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.bonus,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },

  resetFilterState({ commit, dispatch }, predefined) {
    commit('SET_FILTER_DEFAULTS')
    // dispatch(`loadData`, {silent: true})
  },

  loadData({ commit, getters, dispatch }, filter) {
    const vm = (this as any)._vm

    return new Promise((resolve, reject) => {
      let params: any = {}
      if (filter && !filter.silent) {
        params = {
          brands: JSON.stringify(filter.brands.map((i: any) => i.id)),
          cities: JSON.stringify(filter.cities.map((i: any) => i.id)),
          categories: JSON.stringify(filter.merchant_categories.map((i: any) => i.id)),
          registration_type: filter.registration_type,
          outlets_count_min: filter.outlets_count[0],
          outlets_count_max: filter.outlets_count[1],
          receipts_count_min: filter.receipts_count[0],
          receipts_count_max: filter.receipts_count[1],
          receipts_sum_min: filter.receipts_sum[0] * 100,
          receipts_sum_max: filter.receipts_sum[1] * 100,
          feedbacks_count_min: filter.feedbacks_count[0],
          feedbacks_count_max: filter.feedbacks_count[1],
          marks_count_min: filter.marks_count[0],
          marks_count_max: filter.marks_count[1],
          common_mark_min: filter.common_mark[0],
          common_mark_max: filter.common_mark[1],
          disсouted_bonuses_min: filter.disсouted_bonuses[0],
          disсouted_bonuses_max: filter.disсouted_bonuses[1],
          accural_bonuses_min: filter.accural_bonuses[0],
          accural_bonuses_max: filter.accural_bonuses[1],
          balance_min: filter.balance[0],
          balance_max: filter.balance[1],
        }
      }
      if (!filter || !filter.silent) {
        commit('START_LOADING')
      }

      vm.$http
        .get('/admins/rest/v1/reports/bonus_stats', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve()
    })
  },

  loadMerchantsFilterRanges({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchants/filter_ranges`)
        .then((response: AxiosResponse) => {
          commit('SET_FILTER_RANGES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadMerchants({ commit, state }, filter) {
    const vm = (this as any)._vm
    commit('START_FILTER')
    return new Promise((resolve, reject) => {
      let params: any = {}
      if (filter) {
        params = {
          city_id: filter.city_id,
          brand_id: filter.brand_id,
          category_id: filter.merchant_category_id,
          registration_type: filter.registration_type,
          outlets_count_min: filter.outlets_count[0],
          outlets_count_max: filter.outlets_count[1],
          receipts_count_min: filter.receipts_count[0],
          receipts_count_max: filter.receipts_count[1],
          receipts_sum_min: filter.receipts_sum[0] * 100,
          receipts_sum_max: filter.receipts_sum[1] * 100,
          feedbacks_count_min: filter.feedbacks_count[0],
          feedbacks_count_max: filter.feedbacks_count[1],
          marks_count_min: filter.marks_count[0],
          marks_count_max: filter.marks_count[1],
          common_mark_min: filter.common_mark[0],
          common_mark_max: filter.common_mark[1],
          disсouted_bonuses_min: filter.disсouted_bonuses[0],
          disсouted_bonuses_max: filter.disсouted_bonuses[1],
          accural_bonuses_min: filter.accural_bonuses[0],
          accural_bonuses_max: filter.accural_bonuses[1],
          balance_min: filter.balance[0],
          balance_max: filter.balance[1],
        }
      }
      params.per_page = 1000
      vm.$http
        .get(`/admins/rest/v1/merchants`, { params })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANTS', response.data)
          commit('END_FILTER')
        })
        .catch((error: AxiosError) => {
          commit('END_FILTER')
          reject(error.response?.data)
        })
    })
  },

  loadCities({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/cities`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_CITIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadBrands({ commit, state }) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/brands`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_BRANDS', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  loadMerchantCategories({ commit, state }, predefined) {
    const vm = (this as any)._vm
    return new Promise((resolve, reject) => {
      vm.$http
        .get(`/admins/rest/v1/merchant_categories`, { params: { per_page: 1000 } })
        .then((response: AxiosResponse) => {
          commit('SET_MERCHANT_CATEGORIES', response.data)
          // commit('LOADED_SUCCESS');
          // resolve();
        })
        .catch((error: AxiosError) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error.response?.data)
          commit('LOADED_ERROR')
          reject(error.response?.data)
        })
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  saveObject({ commit, state }, { id, copy }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const cleanForm: any = {}
      for (const i in state.form.merchants) {
        for (const j in state.merchants) {
          if (state.merchants[j].id === state.form.merchants[i].merchant_id) {
            state.form.merchants[i] = state.merchants[j]
          }
        }
      }
      for (const key of AVAILABLE_FIELDS) {
        cleanForm[key] = state.form[key]
      }

      commit('START_LOADING')

      let request = null

      if (copy) {
        request = vm.$http.post(`/admins/rest/v1/bonus`, {
          bonus: cleanForm,
        })
      }
      else if (id) {
        request = vm.$http.patch(`/admins/rest/v1/bonus/${id}`, {
          bonus: cleanForm,
        })

      }
      else {
        request = vm.$http.post(`/admins/rest/v1/bonus`, {
          bonus: cleanForm,
        })
      }

      request
        .then((response: AxiosResponse) => {
          commit('LOADED_SUCCESS')

          resolve(response.data)
        })
        .catch((errorResponse: AxiosError) => {
          // eslint-disable-next-line
          console.error('Fatal error', errorResponse)

          if (errorResponse.response?.status === 500) {
            commit('LOADED_ERROR')
            reject()
          }
          else {
            const serverError = errorResponse.response?.data?.error

            commit('LOADED_SUCCESS')
            commit('SET_SERVER_ERROR', serverError)
            reject(serverError)
          }
        })
    })
  },
  checkIntersection({ state, commit }) {
    const vm = (this as any)._vm
    const object_id = state.object ? state.object.id : null
    vm.$http
      .get(`/admins/rest/v1/bonus`, {
        params: {
          start_date: state.form.start_date,
          end_date: state.form.end_date,
        },
      }).then((response: AxiosResponse) => {
      const merchat_ids = state.form.merchants.map((i: any) => i.merchant_id)
      console.log(state.form.merchants, merchat_ids)
      const bonus = response.data.bonus.filter((item: any) => {
        const _ids = item.merchants.map((i: any) => i.id)
        const merchants = merchat_ids.filter((m: any) => _ids.includes(m))
        return (item.add_new_merchants || item.common || merchants.length > 0) && item.id !== object_id
      })

      state.blocked = bonus.length > 0 && (state.form.add_new_merchants || state.form.common) && merchat_ids.length > 0
    })
  },
}

const compare: any = ((a: any, b: any) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
})

export const $mutations: MutationTree<BonusFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_FILTER: (state) => {
    state.filering = true
  },
  END_FILTER: (state) => {
    state.filering = false
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },
  SET_ITEMS_LIST(state, data) {
    state.report_data = data.data
    const a = 0
    const merchants = state.object ? state.object.merchants : []
    state.form.merchants = []
    for (const i in merchants) {
      for (const j in state.report_data) {
        if (merchants[i].id === state.report_data[j].merchant_id) {
          state.form.merchants.push(state.report_data[j])
        }
      }
    }
  },
  SET_FILTER_DEFAULTS: (state) => {
    state.filter = {
      city_id: null,
      cities: [],
      brands: [],
      merchants: [],
      merchant_category_id: null,
      registration_type: 'all',
      outlets_count: [0, 0],
      receipts_count: [0, 0],
      receipts_sum: [0, 0],
      feedbacks_count: [0, 0],
      marks_count: [0, 0],
      common_mark: [0, 5],
      disсouted_bonuses: [0, 0],
      accural_bonuses: [0, 0],
      balance: [0, 0],
    }
  },
  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)
    if (formData.category) {
      formData.category_id = formData.category.id
    }

    state.form = {
      ...formData,
    }
  },
  SET_MERCHANTS: (state, { merchants }) => {
    state.merchants = merchants.sort(compare)
  },
  SET_CITIES: (state, { cities }) => {
    state.cities = cities
  },
  SET_MERCHANT_CATEGORIES: (state, { merchant_categories }) => {
    state.merchant_categories = merchant_categories.sort(compare)
  },
  SET_BRANDS: (state, { brands }) => {
    state.brands = brands.sort(compare)
  },
  SET_FILTER_RANGES: (state, { filter_ranges }) => {
    state.filter.outlets_count = [0, filter_ranges.outlets_count + 0]
    state.filter.receipts_count = [0, filter_ranges.receipts_count + 0]
    state.filter.receipts_sum = [0, filter_ranges.receipts_max / 100]
    state.filter.feedbacks_count = [0, filter_ranges.feedbacks_count + 0]
    state.filter.marks_count = [0, filter_ranges.marks_count + 0]
    state.filter_ranges = Object.assign({}, filter_ranges)
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
    state.form.merchants = []
  },

  CHANGE_VALUE(state: BonusFormState, { field, newValue }) {
    if (field === 'value_feedback' || field === 'value_mark' || field === 'start_number') {
      state.form[field] = parseInt(newValue)
    }
    else if (field === 'min_sum') {
      state.form[field] = parseFloat(newValue)
    }
    else {
      state.form[field] = newValue
    }
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },

}

export const $getters: GetterTree<BonusFormState, RootState> = {
  merchantsOptions: (state: any) => {
    return state.merchants.map((item: any) => {
      return {
        text: item.name,
        value: item.id,
      }
    })
  },
}

export const STORE_KEY = '$_bonus_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
