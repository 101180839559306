

















































































































































































































































































































































































































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapGetters, mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf, minLength, sameAs } from 'vuelidate/lib/validators'
import { getFieldError } from '@/core/tools/forms/get_field_error'
import { AxiosResponse, AxiosError } from 'axios'

import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'
// import { BTable } from 'bootstrap-vue/esm/components/table'


@Component({
  mixins: [validationMixin],
  computed: {
    ...mapState(STORE_KEY, [
      'loaded', 'loading', 'fatalError', 'serverErrors', 'filter',
      'form', 'formDirty', 'merchants', 'cities', 'merchant_categories',
      'filering', 'filter_ranges', 'brands', 'checkedRows', 'report_data',
      'blocked',
    ]),
    ...mapGetters(STORE_KEY, [
      'merchantsOptions',
    ]),
    ...mapActions(STORE_KEY, [
      'loadMerchants', 'loadCities', 'loadMerchantCategories',
      'loadMerchantsFilterRanges',
    ]),
  },
  methods: {
    getFieldError,
  },
  validations() {
    const rules: any = {
      type: { required },
      start_date: { required },
      end_date: { required },
      programm_name: {},
      merchants: {},
      value_feedback: { required },
      value_mark: { required },
      common: {},
      add_new_merchants: {},
      min_sum: { required },
      category_id: {},
    }

    return {
      form: rules,
    }
  },
  components: {
    TitleBar,
    // BTable
  },
})
export default class CardSeriesForm extends Vue {

  public showModal: any = false
  public viewFilter: boolean = true
  public filter!: any
  public form!: any

  get isNew() {
    return !Number.isInteger(this.$route.params.id)
  }

  get titleStack() {
    return [
      'Бонусы', (this.isNew ? 'Новый бонус' : 'Редактирование бонуса'),
    ]
  }

  public toggleFilter() {
    this.viewFilter = !this.viewFilter
  }

  public setFilter() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.filter)
    this.showModal = false
  }

  public changeValue(field: string, value: any) {
    this.$store.dispatch(`${STORE_KEY}/changeFormValue`, {
      field, newValue: value,
    })

    // @ts-ignore
    this.$v.form[field].$touch()
  }

  public removeMerchant(merchant: any) {
    this.form.merchants.pop(merchant)
  }

  public checkIntersection() {
    if (this.form.start_date && this.form.end_date) {
      this.$store.dispatch(`${STORE_KEY}/checkIntersection`)
    }
  }

  @Watch('form.start_date')
  public watchStartDate() {
    this.checkIntersection()
  }

  @Watch('form.end_date')
  public watchEndDate() {
    this.checkIntersection()
  }

  @Watch('form.add_new_merchants')
  public watchAddNewMerchants() {
    this.checkIntersection()
  }

  @Watch('form.common')
  public watchCommon() {
    this.checkIntersection()
  }

  @Watch('form.merchants')
  public filterMerchants(a: any, b: any) {
    if (a && b && a.length !== b.length) {
      // @ts-ignore
      this.$v.form.merchants.$touch()
    }
    this.checkIntersection()
  }

  public resetFilter() {
    this.$store.dispatch(`${STORE_KEY}/resetFilterState`)
    // this.showModal = false;
  }

  public submitObject() {
    this.$v.form.$touch()

    if (!this.$v.form.$error) {
      this.$store.dispatch(`${STORE_KEY}/saveObject`, { id: this.$route.params.id, copy: this.$route.params.copy })
        .then((result) => {
          this.$buefy.snackbar.open(`Данные успешно сохранены`)
          this.$router.push({ name: 'bonus-list' })
        })
        .catch((e) => {
          console.log(1, e)
          const msg = `При сохраненит возникли проблемы. Попробуйте позже.`
          this.$buefy.snackbar.open({
            type: 'is-warning',
            message: msg,
          })
        })
    }
  }

  // ---------------------------------------------------------------------------

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/initState`, {
      id: this.$route.params.id,
      predefined: this.$route.query,
    })
    this.$store.dispatch(`${STORE_KEY}/loadData`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantsFilterRanges`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchants`)
    this.$store.dispatch(`${STORE_KEY}/loadCities`)
    this.$store.dispatch(`${STORE_KEY}/loadBrands`)
    this.$store.dispatch(`${STORE_KEY}/loadMerchantCategories`)
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }
}
